import { Prop, Component, Vue, Ref } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import ValidationModel from '@/models/Components/Inputs/ValidationModel';
import LooseObject from '@/models/Objects/LooseObject';
import Client from 'getaddress-api';
import CustomGoogleAutocomplete from 'vue-custom-google-autocomplete';
import VueAppleLogin from 'vue-apple-login';
import store from '@/store';
import { Select } from 'ant-design-vue';
import { name } from '@azure/msal-browser/dist/packageMetadata';
import VueJwtDecode from 'vue-jwt-decode';

@Component
export default class SingleSignOnInput extends Vue {
    @Prop() public props!: LooseObject;
    @Prop() public value!: string;
    @Ref('addressSelect') private addressSelect!: Select;
    @Ref('googleSelect') private googleSelect!: Select;
    private validationArray: ValidationModel[] = [];
    private inputValue = '';
    private authorized: boolean = false;
    private displayGoogle: boolean = false;
    private displayApple: boolean = false;


    protected created() {
        for (const ind of Object.keys(this.props.provider)) {
            if (this.props.provider[ind].title === 'Google' && this.authorized === false) {
                this.displayGoogle = true;
            }
            if (this.props.provider[ind].title === 'Apple' && this.authorized === false) {
                this.displayApple = true;
            }
        }
        Vue.use(VueAppleLogin, {
            clientId: 'com.example.signin',
            scope: 'name email',
            redirectURI: document.location.origin,
            state: 'Initial user authentication request',
            usePopup: true,
        });
    }

    protected mounted() {
        window.google.accounts.id.initialize({
            client_id: '731423710543-g4si66ed0bgc25kq9g0skj8h3c2dcef1.apps.googleusercontent.com',
            callback: this.handleCredentialResponse,
        });
        if (document.getElementById('googleSignInDiv')) {
            window.google.accounts.id.renderButton(
                document.getElementById('googleSignInDiv'),
                { theme: 'outline', size: 'large' },  // customization attributes
            );
        }
    }


    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }

    private async onChange() {
        this.$emit('update:value', this.props.options);
    }

    private isValueValid() {
        if (this.props.required && !this.value) {
            return false;
        }
        let valid = true;
        if (this.props.minLength && this.props.maxLength) {
            const { min, max } = { min: this.props.minLength, max: this.props.maxLength };
            valid = this.value.length >= min && this.value.length <= max;
        }
        if (this.props.pattern && valid) {
            const match = this.value.match(new RegExp(this.props.pattern));
            valid = match ? true : false;
        }
        return valid;
    }

    private submitForm() {
        document.getElementById('submit')?.click();
    }

    private submitValue() {
        if (this.isValueValid()) {
            EventBus.$emit('submitValue', this.props.elementId, this.value, this.props.label);
        }
    }

    private updateInputValues(inputValue: string) {
        this.inputValue = inputValue;
        this.$emit('update:value', this.inputValue);
    }

    private handleCredentialResponse(response: any) {
        const decoded = VueJwtDecode.decode(response.credential);
        if (decoded.sub) {
            this.authorized = true;
            this.displayApple = false;
            this.displayGoogle = false;
            this.submitForm();
        }
    }

    private callSuccess(response: any) {
        this.displayApple = false;
        this.displayGoogle = false;
    }

    private callFail(response: any) {
        this.displayApple = true;
        this.displayGoogle = true;
    }

}
